import React from "react"
import css from "./button.css"

export default function Button(props) {
  const { children, type, id, style, onClick } = props

  return (
    <button
      {...{
        css,
        type,
        id,
        style,
        ...(onClick && { onClick }),
      }}
    >
      {children}
    </button>
  )
}
