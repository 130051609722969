import React from "react"
import { Link } from "gatsby"
import useImages from "../hooks/useImages"
import Img from "gatsby-image"
import Button from "./button"

import Section from "./section"
import Separator from "../components/separator"

import css from "./information.css"

export default function Us() {
  const { restaurante, peluqueria, hotel, autobus, menu, wedding } = useImages()

  return (
    <Section>
      <div css={css}>
        <div className={"tile"}>
          <Img fluid={menu.childImageSharp.fluid} alt=""></Img>
          <div className="tile__content">
            <div>
              <h3>Menú</h3>
              <Separator />
            </div>
            <p>Aquí tenéis el menú de la boda.</p>
            <Button>
              <Link to={"/menu"}>Ver más</Link>
            </Button>
          </div>
        </div>
        <div className={"tile"}>
          <Img fluid={hotel.childImageSharp.fluid} alt=""></Img>
          <div className="tile__content">
            <div>
              <h3>Hoteles</h3>
              <Separator />
            </div>
            <p>
              Aquí os dejamos una selección de hoteles que por su ubicación
              creemos que son buenas opciones.
            </p>
            <Button>
              <Link to={"/hoteles"}>Ver más</Link>
            </Button>
          </div>
        </div>
        <div className={"tile"}>
          <Img fluid={peluqueria.childImageSharp.fluid} alt=""></Img>
          <div className="tile__content">
            <div>
              <h3>Peluquerías</h3>
              <Separator />
            </div>
            <p>Aquí os dejamos una lista de peluquerías muy cerca del lugar de la celebración.</p>
            <Button>
              <Link to={"/peluquerias"}>Ver más</Link>
            </Button>
          </div>
        </div>
        <div className={"tile"}>
          <Img fluid={restaurante.childImageSharp.fluid} alt=""></Img>
          <div className="tile__content">
            <div>
              <h3>Restaurantes</h3>
              <Separator />
            </div>
            <p>
              No podéis estar por Asturias y no comer como Dios manda! Aquí os
              dejamos recomendaciones de restaurantes.
            </p>
            <Button>
              <Link to={"/restaurantes"}>Ver más</Link>
            </Button>
          </div>
        </div>
        <div className={"tile"}>
          <Img fluid={autobus.childImageSharp.fluid} alt=""></Img>
          <div className="tile__content">
            <div>
              <h3>Paradas del autocar</h3>
              <Separator />
            </div>
            <p>
              El horario y ubicación de todas las paradas de
              autobús. Sed puntuales que al autobús no espera
            </p>
            <Button>
              <Link to={"/paradas-autobus"}>Ver más</Link>
            </Button>
          </div>
        </div>
        <div className={"tile"}>
          <Img fluid={wedding.childImageSharp.fluid} alt=""></Img>
          <div className="tile__content">
            <div>
              <h3>Wedding Planner</h3>
              <Separator />
            </div>
            <p>
              Organizar una boda no es fácil. Para nuestra boda hemos decidido trabajar con Judit y gracias a ella la organización está siendo mucho más sencilla. No dudéis en contactar con ella para cualquier cosa referente a la boda o para organización de eventos. Aqui  os facilitamos su telefono y web: <a href="callto:691870721">691870721</a> <a target="_blank" rel="noopener" href="https://www.ritabyju.com">www.ritabyju.com</a>
            </p>
          </div>
        </div>
      </div>
    </Section>
  )
}
 