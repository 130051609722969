import { css } from "@emotion/core"

export default css`
  margin: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;

  div.image-border {
    border: 2px solid #c9c9c9;
    padding: 1rem;
    position: relative;
    transition: all 0.2s;

    > div {
      position: absolute;
      background-color: #f8f8f8;

      &:nth-child(1) {
        width: 80%;
        height: 102%;
        top: -1%;
        left: 10%;
      }

      &:nth-child(2) {
        height: 80%;
        width: 102%;
        top: 10%;
        left: -1%;
      }
    }
  }

  .tile {
    position: relative;
    margin: 2rem;
    padding: 2rem;
    max-width: 350px;
    width: 100%;
    text-align: center;
    color: #c9c9c9;
    height: 350px;

    .gatsby-image-wrapper {
      position: absolute !important;
      overflow: hidden;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: -1;
    }

    .tile__content {
      background-color: rgba(250, 250, 250, 0.9);
      height: 100%;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      padding: 1rem;
      box-sizing: border-box;
      align-items: center;
      color: #a4a4a4;
    }
    p {
      font-size: 1rem;
      margin: 1rem;
    }
    h3 {
      color: #a4a4a4;
    }
    a {
      color: #777;
    }
  }

  @media (max-width: 720px) {
    margin-bottom: 4rem;
    .tile {
      margin: 1rem;
      padding: 1rem;
    }
  }
`
