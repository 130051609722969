import { css } from "@emotion/core"

export default css`
  font-family: "Cinzel";
  background: none;
  font-size: 1.3rem;
  width: fit-content;
  border: 1px solid #c9c9c9;
  color: #c9c9c9;
  padding: 1rem 2rem;
  cursor: pointer;
  transition: all 0.5s;

  a {
    color: #a4a4a4;
    text-decoration: none;
  }

  &.soft {
    border: 1px solid #c9c9c9;
    color: #c9c9c9;
  }

  &:hover {
    border: 1px solid #a4a4a4;
    color: #a4a4a4;
  }
`
